<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="
                    (item.active && 'active') ||
                    (item.finish && 'navtop-canclick')
                  "
                  @click="
                    item.finish &&
                      stepwh_signup < 8 &&
                      changeStep(item.linkItems)
                  "
                >
                  <a href class="d-flex flex-column">
                    <span v-if="item.finish" class="icons">
                      <img src="@/assets/icons/icon-checkmark.svg" alt />
                    </span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div
            class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto"
            style="
              height: auto;
              margin-bottom: 0px;
              margin-right: 0px;
              max-height: 570px;
            "
          >
            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep2.select_room_type") }}</h3>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep2.room_type") }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('roomtype')"
                  @mouseleave="mouseOver('roomtype')"
                >
                  <a data-toggle="dropdown" data-display="static" id="roomtype">
                    <v-select
                      v-model="addNewRoomData.roomtype"
                      :items="itemsRoomType"
                      item-text="name"
                      item-value="value"
                      label
                      :placeholder="$t('SignupStep.select_one')"
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @change="selectRoomTypeFunc"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('roomtype')"
                          @mouseleave="mouseOver('roomtype')"
                        >
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:no-data>
                        <v-btn
                          v-if="!emptyRoomtypes"
                          @click="changeLanguageData"
                          text
                          color="pink"
                          style="width: 100%"
                        >{{ $t("SignupStep.btn_refresh") }}</v-btn>
                        <div
                          role="listbox"
                          tabindex="-1"
                          class="v-list v-select-list v-sheet theme--light v-list--dense theme--light"
                          data-v-289cbba2="true"
                          id="list-125"
                        >
                          <div tabindex="-1" class="v-list-item theme--light">
                            <div class="v-list-item__content">
                              <div class="v-list-item__title">{{ $t("Alert.allroomtypesused") }}</div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-select>
                  </a>
                </div>
              </div>

              <div class="form-group">
                <label class="light">
                  {{
                  $t("SignupStep2.room_type_name")
                  }}
                </label>
                <div
                  class="dropdown select"
                  @click="getfocus('roomname')"
                  @mouseleave="mouseOver('roomname')"
                >
                  <a data-toggle="dropdown" data-display="static" id="roomname">
                    <v-select
                      v-model="addNewRoomData.name"
                      :items="itemsRoomName"
                      item-text="name"
                      item-value="value"
                      label
                      hide-no-data
                      :placeholder="$t('SignupStep.select_one')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('roomname')"
                          @mouseleave="mouseOver('roomname')"
                        >
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:no-data>
                        <v-btn
                          @click="initRoomTypes"
                          text
                          color="pink"
                          style="width: 100%"
                        >{{ $t("SignupStep.btn_refresh") }}</v-btn>
                      </template>
                    </v-select>
                  </a>
                </div>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep2.smokingrule") }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('smokingrule')"
                  @mouseleave="mouseOver('smokingrule')"
                >
                  <a data-toggle="dropdown" data-display="static" id="smokingrule">
                    <v-select
                      v-model="addNewRoomData.smokingrule"
                      :items="itemsSmokingRules"
                      item-text="name"
                      item-value="value"
                      label
                      :placeholder="$t('SignupStep.select_one')"
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('smokingrule')"
                          @mouseleave="mouseOver('smokingrule')"
                        >
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:no-data>
                        <v-btn
                          @click="initSmokingRules"
                          text
                          color="pink"
                          style="width: 100%"
                        >{{ $t("SignupStep.btn_refresh") }}</v-btn>
                      </template>
                    </v-select>
                  </a>
                </div>
              </div>

              <div class="form-group">
                <label class="light">{{ $t("SignupStep2.room_number") }}</label>
                <input
                  id="room_number"
                  type="number"
                  min="0"
                  class="form-control"
                  placeholder
                  name
                  @keypress="isNumber($event)"
                  v-model="addNewRoomData.amount"
                />
                <span class="error-text-input" v-if="showwarnamount">
                  {{
                  $t("Alert.pleasefilldata")
                  }}
                </span>
              </div>
            </div>

            <div v-if="addNewRoomData.options.length" class="form-box">
              <h3 class="title-md">{{ $t("SignupStep2.bed_type") }}</h3>

              <template v-for="(element, index) in addNewRoomData.options">
                <div :key="index" class="form-group">
                  <label v-if="index == 0">
                    <span class="regular">{{ element.option }}</span>
                  </label>
                  <label v-else>
                    <span class="regular">
                      {{ element.option }} ({{
                      $t("SignupStep2.ifhave")
                      }})
                    </span>
                  </label>

                  <v-row v-if="element.choosechoices.length">
                    <v-col cols="12" sm="12" md="7" lg="7" class="text-center">
                      <span class="regular">
                        {{
                        $t("SignupStep2.bed_type")
                        }}
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="4" class="text-center">
                      <span class="regular">
                        {{
                        $t("SignupStep2.numofbed")
                        }}
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="1" lg="1"></v-col>
                  </v-row>

                  <v-row v-for="(item, i) in element.choosechoices" :key="i + 999">
                    <v-col :key="componentKey" cols="12" sm="12" md="7" lg="7">
                      <div class="quantity d-flex">
                        <div
                          class="dropdown select"
                          style="width: 100%"
                          @click="getfocus(`newchoices_${i}`)"
                          @mouseleave="mouseOver(`newchoices_${i}`)"
                        >
                          <a data-toggle="dropdown" data-display="static" :id="`newchoices_${i}`">
                            <v-select
                              v-model="item.value"
                              :items="checkBedChoices(item, index, i)"
                              item-text="choice"
                              item-value="_id"
                              label
                              :placeholder="$t('SignupStep2.anotherbed')"
                              :no-data-text="$t('SignupStep.nodata')"
                              color="pink"
                              solo
                              flat
                              dense
                              single-line
                              hide-details
                              @change="checkBedChoices(item, index, i)"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content
                                  @mouseenter="getfocus(`newchoices_${i}`)"
                                  @mouseleave="mouseOver(`newchoices_${i}`)"
                                >
                                  <v-list-item-title v-html="data.item.choice"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                              <template v-slot:no-data>
                                <v-btn
                                  @click="checkBedChoices(item, index, i)"
                                  text
                                  color="pink"
                                  style="width: 100%"
                                >{{ $t("SignupStep.btn_refresh") }}</v-btn>
                              </template>
                            </v-select>
                          </a>
                        </div>
                      </div>
                      <span class="error-text-input" v-if="item.showwarn">
                        {{
                        $t("Alert.pleasefilldata")
                        }}
                      </span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="4"
                      lg="4"
                      class="d-flex justify-center"
                      :class="
                        item.showwarn ? 'align-start mt-3' : 'align-center'
                      "
                    >
                      <div class="bootstrap-touchspin-wrap m-small">
                        <v-btn
                          outlined
                          icon
                          @click="decreaseChoiceNumber(item)"
                          :disabled="item.number === 1 || !item.number"
                          class="hover_numberbtn"
                        >
                          <v-icon x-small>mdi-minus</v-icon>
                        </v-btn>
                        <span
                          :key="componentKey"
                          id="qty1"
                          class="input-touchspin numbersOnly f16 text-center mx-5"
                          type="text"
                          size="2"
                          name="qty"
                        >{{ item.number }}</span>
                        <v-btn
                          outlined
                          icon
                          @click="increaseChoiceNumber(item)"
                          class="hover_numberbtn"
                        >
                          <v-icon x-small>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="1"
                      lg="1"
                      class="d-flex justify-center"
                      :class="
                        item.showwarn ? 'align-start mt-4' : 'align-center'
                      "
                    >
                      <div class="delete">
                        <a
                          @mouseenter="hoverRemoveChoice(item, 'hover')"
                          @mouseleave="outRemoveChoice(item)"
                          @click="removeNewChoice(item, index, i)"
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M12.7584 7.24609C12.4997 7.24609 12.29 7.45577 12.29 7.71448V16.5669C12.29 16.8255 12.4997 17.0353 12.7584 17.0353C13.0171 17.0353 13.2268 16.8255 13.2268 16.5669V7.71448C13.2268 7.45577 13.0171 7.24609 12.7584 7.24609Z"
                                :fill="item.hover ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M7.23206 7.24609C6.97335 7.24609 6.76367 7.45577 6.76367 7.71448V16.5669C6.76367 16.8255 6.97335 17.0353 7.23206 17.0353C7.49076 17.0353 7.70044 16.8255 7.70044 16.5669V7.71448C7.70044 7.45577 7.49076 7.24609 7.23206 7.24609Z"
                                :fill="item.hover ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M3.20431 5.95419V17.4942C3.20431 18.1762 3.45442 18.8168 3.89133 19.2764C4.32623 19.7373 4.93147 19.9989 5.56488 20H14.4268C15.0604 19.9989 15.6657 19.7373 16.1004 19.2764C16.5373 18.8168 16.7874 18.1762 16.7874 17.4942V5.95419C17.6559 5.72366 18.2187 4.8846 18.1025 3.99339C17.9862 3.10236 17.2271 2.43583 16.3284 2.43565H13.9303V1.85017C13.933 1.35782 13.7383 0.885049 13.3898 0.537238C13.0413 0.18961 12.5678 -0.00396362 12.0754 6.15416e-05H7.91631C7.42396 -0.00396362 6.95045 0.18961 6.60191 0.537238C6.25337 0.885049 6.0587 1.35782 6.06144 1.85017V2.43565H3.66336C2.76465 2.43583 2.00554 3.10236 1.88918 3.99339C1.773 4.8846 2.33579 5.72366 3.20431 5.95419ZM14.4268 19.0632H5.56488C4.76406 19.0632 4.14107 18.3753 4.14107 17.4942V5.99536H15.8506V17.4942C15.8506 18.3753 15.2277 19.0632 14.4268 19.0632ZM6.99821 1.85017C6.9951 1.60628 7.09097 1.37154 7.26405 1.19938C7.43695 1.02721 7.67224 0.932619 7.91631 0.936827H12.0754C12.3195 0.932619 12.5548 1.02721 12.7277 1.19938C12.9007 1.37136 12.9966 1.60628 12.9935 1.85017V2.43565H6.99821V1.85017ZM3.66336 3.37242H16.3284C16.794 3.37242 17.1714 3.74987 17.1714 4.21551C17.1714 4.68114 16.794 5.05859 16.3284 5.05859H3.66336C3.19772 5.05859 2.82027 4.68114 2.82027 4.21551C2.82027 3.74987 3.19772 3.37242 3.66336 3.37242Z"
                                :fill="item.hover ? '#EE3068' : '#333333'"
                              />
                              <path
                                d="M9.99572 7.24609C9.73702 7.24609 9.52734 7.45577 9.52734 7.71448V16.5669C9.52734 16.8255 9.73702 17.0353 9.99572 17.0353C10.2544 17.0353 10.4641 16.8255 10.4641 16.5669V7.71448C10.4641 7.45577 10.2544 7.24609 9.99572 7.24609Z"
                                :fill="item.hover ? '#EE3068' : '#333333'"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="form-group mt-3">
                    <button
                      class="btn"
                      style="height: initial"
                      @click="addNewChoice(element.choice, index)"
                      type="button"
                    >
                      <span>{{ $t("SignupStep2.anotherbed") }}</span>
                    </button>
                  </div>
                </div>
              </template>
            </div>

            <div class="form-box">
              <h3 class="title-md">{{ $t("SignupStep2.customer_number") }}</h3>

              <div class="form-group">
                <div class="quantity d-flex mb-md-4 my-1">
                  <label class="pl-md-4">
                    {{
                    $t("SignupStep2.normal_customer_number")
                    }}
                  </label>
                  <div class="bootstrap-touchspin-wrap m-small">
                    <v-btn
                      outlined
                      icon
                      @click="decreaseNormalNumber()"
                      :disabled="
                        addNewRoomData.normal.amount === 1 ||
                        !addNewRoomData.normal.amount
                      "
                      class="hover_numberbtn"
                    >
                      <v-icon x-small>mdi-minus</v-icon>
                    </v-btn>
                    <span
                      id="qty1"
                      class="input-touchspin numbersOnly f16 text-center mx-5"
                      type="text"
                      size="2"
                      name="qty"
                    >{{ addNewRoomData.normal.amount }}</span>
                    <v-btn outlined icon @click="increaseNormalNumber()" class="hover_numberbtn">
                      <v-icon x-small>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>

                <div class="quantity d-flex mb-md-4 my-1">
                  <label class="pl-md-4">
                    {{
                    $t("SignupStep2.max_customer_number")
                    }}
                  </label>
                  <div class="bootstrap-touchspin-wrap m-small">
                    <v-btn
                      outlined
                      icon
                      @click="decreaseMaxNumber()"
                      :disabled="
                        addNewRoomData.max.amount <=
                          addNewRoomData.normal.amount ||
                        addNewRoomData.max.amount === 1 ||
                        !addNewRoomData.max.amount
                      "
                      class="hover_numberbtn"
                    >
                      <v-icon x-small>mdi-minus</v-icon>
                    </v-btn>
                    <span
                      id="qty1"
                      class="input-touchspin numbersOnly f16 text-center mx-5"
                      type="text"
                      size="2"
                      name="qty"
                    >{{ addNewRoomData.max.amount }}</span>
                    <v-btn outlined icon @click="increaseMaxNumber()" class="hover_numberbtn">
                      <v-icon x-small>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-box">
              <div class="form-group roomsize">
                <label class="light">{{ $t("SignupStep2.room_size") }}</label>
                <div class="input-group">
                  <v-row>
                    <v-col cols="12" lg="6" md="6" sm="12" class="px-0">
                      <input
                        id="room_size"
                        type="number"
                        min="0"
                        @keypress="isNumber($event)"
                        class="form-control medium input-size-roomtype"
                        name
                        placeholder
                        v-model="addNewRoomData.roomcapacity"
                      />
                    </v-col>

                    <v-col cols="12" lg="6" md="6" sm="12" class="px-0">
                      <div
                        class="dropdown select select-size-roomtype"
                        @click="getfocus('roomcapacityunit')"
                        @mouseleave="mouseOver('roomcapacityunit')"
                      >
                        <a
                          data-toggle="dropdown"
                          data-display="static"
                          id="roomcapacityunit"
                          class="a-select-size-roomtype"
                        >
                          <v-select
                            v-model="addNewRoomData.roomcapacityunit"
                            :items="itemsRoomCapacityUnit"
                            item-text="name"
                            item-value="value"
                            label
                            :placeholder="$t('SignupStep.select_one')"
                            :no-data-text="$t('SignupStep.nodata')"
                            color="pink"
                            class="text-right"
                            solo
                            flat
                            dense
                            single-line
                            hide-details
                          >
                            <template v-slot:item="data">
                              <v-list-item-content
                                @mouseenter="getfocus('roomcapacityunit')"
                                @mouseleave="mouseOver('roomcapacityunit')"
                              >
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <template v-slot:no-data>
                              <v-btn
                                @click="initMeasures"
                                text
                                color="pink"
                                style="width: 100%"
                              >{{ $t("SignupStep.btn_refresh") }}</v-btn>
                            </template>
                          </v-select>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <span class="error-text-input" v-if="showwarnunitnumber">
                  {{
                  $t("Alert.pleasefilldata")
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <div class="pa-0 d-flex flex-row align-center justify-space-between flex-100per">
              <button
                type="button"
                class="btn-back gl-bt hover_btn ml-0"
                style="text-transform: capitalize !important"
                @click="goback()"
              >
                <span>{{ `< ${$t("servicepage.back")}` }}</span>
              </button>

              <button
                class="btn xs gl-bt hover_btn mx-0"
                type="button"
                :disabled="!canupdateroomtype"
                @click="addNewRoomDataFunc()"
              >
                <span>{{ $t("SignupStep1.btn_save") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox
            >
              <span class="btn-open-picture">
                {{
                $t("SignupStep4.clickopenpicture")
                }}
              </span>
              <img :src="pathrightphoto" alt />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "UpdateRoomScreen",
  metaInfo: {
    title: "Update Room",
    titleTemplate: "%s | Aigent"
  },
  data: () => ({
    stepwh_signup: 2,
    itemsSmokingRules: [],
    itemsRoomType: [],
    itemsRoomName: [],
    addNewRoomData: {
      options: [],
      normal: {
        name: "จำนวนผู้เข้าพักปกติ",
        value: "normal",
        amount: 2,
        hover: false
      },
      max: {
        name: "จำนวนผู้เข้าพักสูงสุด",
        value: "maximum",
        amount: 2,
        hover: false
      },
      roomcapacity: "",
      roomcapacityunit: ""
    },
    itemsRoomCapacityUnit: [],
    itemsAllRoomData: [],
    propertyID: "",
    roomID: "",
    value_element: "",
    hoverdelinroomicon: false,
    hoverdelcommonroomicon: false,
    componentKey: 0,
    itemsStep: [],
    pathrightphoto: "",
    canupdateroomtype: false,
    emptyRoomtypes: true,
    selectoldroomname: "",
    selectoldroomid: "",
    userToken: "",
    roomIdOldData: {},
    showwarnamount: false,
    showwarnunitnumber: false
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.roomID = self.$route.params.roomId;
    if (self.userToken && self.propertyID && self.roomID) {
      self.changeLanguageData();
    } else if (self.userToken && self.propertyID) {
      self.gotoroomtypes();
    } else {
      self.gotosignin();
    }
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
    self.changeLanguageData();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.changeLanguageData);
    this.colorHover();
  },
  watch: {
    addNewRoomData() {
      this.checkcanupdate();
    },
    "addNewRoomData.amount"() {
      this.checkcanupdate();
    },
    "addNewRoomData.roomcapacity"() {
      this.checkcanupdate();
    },
    "addNewRoomData.roomcapacityunit"() {
      this.checkcanupdate();
    }
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    changeLanguageData() {
      const self = this;
      self.initStepData();
      self.initRoomTypes();
      self.initSmokingRules();
      self.initMeasures();
      self.initRoomData();
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkcanupdate() {
      const self = this;
      var numofchoices = 0;
      var numofchoicesdata = 0;
      if (self?.addNewRoomData?.options?.length) {
        self?.addNewRoomData?.options.map(ele => {
          if (ele?.choosechoices?.length)
            ele?.choosechoices?.map(el => {
              numofchoices = numofchoices + 1;
              if (el?.value) numofchoicesdata = numofchoicesdata + 1;
            });
        });
      }

      const elementRoomAmount = document.getElementById("room_number");
      const elementRoomSize = document.getElementById("room_size");

      if (self.showwarnamount) {
        if (
          !self?.addNewRoomData?.amount ||
          Number(self?.addNewRoomData?.amount) === 0
        ) {
          self.showwarnamount = true;
          elementRoomAmount.classList.add("border-pink-focus");
        } else {
          self.showwarnamount = false;
          elementRoomAmount?.classList?.remove("border-pink-focus");
        }
      }

      if (self.showwarnunitnumber) {
        if (
          !self?.addNewRoomData?.roomcapacity ||
          Number(self?.addNewRoomData?.roomcapacity) === 0
        ) {
          self.showwarnunitnumber = true;
          elementRoomSize.classList.add("border-pink-focus");
        } else {
          self.showwarnunitnumber = false;
          elementRoomSize?.classList?.remove("border-pink-focus");
        }
      }

      if (
        self?.addNewRoomData?.roomtype ||
        self?.addNewRoomData?.name ||
        self?.addNewRoomData?.smokingrule ||
        self?.addNewRoomData?.amount ||
        self?.addNewRoomData?.roomcapacity
      ) {
        self.canupdateroomtype = true;
      } else {
        self.canupdateroomtype = false;
      }
    },
    async initStepData() {
      const self = this;
      var temp = {};
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 1
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 2
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 3
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 4
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 5
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 6
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID }
                },
                step: 7
              }
            ];
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        self.gotosignin();
      } finally {
        EventBus.$emit("endloading");
        self.getStepData(self.itemsStep);
      }
    },
    gotoroomtypes() {
      var item = {
        link: `room/${this.userToken}/${this.propertyID}`,
        name: "SignupRoomScreen"
      };
      EventBus.$emit("changePathname", item);
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
      self.initRoomData();
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/step2-${tempLG}.gif`;
    },
    async initRoomData() {
      const self = this;
      var temp = {};
      var items = [];
      var tempItems = [];
      var roomIdData = {};

      var tempRoomOptions = [];
      var tempRoomOldOptions = [];
      var tempRoomChoices = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0];
          if (res?.data?.total) {
            items = temp.rooms;
            temp.rooms.map(el => {
              tempRoomOptions = [];
              // tempRoomOldOptions = []
              if (self.roomID == el._id) roomIdData = el;
              el?.options?.map(ele => {
                tempRoomChoices = [];
                ele?.choices?.map(element => {
                  if (ele?.choices?.length) {
                    tempRoomChoices.push({
                      choice: element?.choice?._id || "",
                      number: element?.number || 1,
                      showwarn: false
                    });
                  }
                });
                tempRoomOptions.push({
                  option: ele?.option?._id,
                  choices: tempRoomChoices
                });
                if (self.roomID == el._id) {
                  tempRoomOldOptions.push({
                    _id: ele?.option?._id,
                    option: ele?.option?.value,
                    choices: tempRoomChoices
                  });
                }
              });

              tempItems.push({
                _id: el?._id,
                room_type:
                  typeof el?.room_type === "object"
                    ? el?.room_type?._id || ""
                    : el?.room_type,
                room_name:
                  typeof el?.room_name === "object"
                    ? el?.room_name?._id || ""
                    : el?.room_name,
                room_name_detail:
                  typeof el?.room_name === "object"
                    ? el?.room_name?.value || ""
                    : el?.room_name,
                room_number: Number(el?.room_number) || 0,
                smoke: el?.smoke || "",
                options: tempRoomOptions,
                customer: {
                  normal: Number(el?.customer?.normal) || 2,
                  max: Number(el?.customer?.max) || 2
                },
                room_size: {
                  size: Number(el?.room_size?.size) || 0,
                  measure: el?.room_size?.measure?._id || ""
                }
              });
              if (self.roomID == el._id) {
                self.roomIdOldData = {
                  _id: el._id,
                  room_type:
                    typeof el?.room_type === "object"
                      ? el?.room_type?._id || ""
                      : el?.room_type,
                  room_name: el?.room_name,
                  room_number: Number(el?.room_number) || 0,
                  smoke: el?.smoke || "",
                  options: tempRoomOldOptions,
                  customer: {
                    normal: Number(el?.customer?.normal) || 2,
                    max: Number(el?.customer?.max) || 2
                  },
                  room_size: {
                    size: Number(el?.room_size?.size) || 0,
                    measure: el?.room_size?.measure?._id || ""
                  }
                };
              }
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message);
      } finally {
        EventBus.$emit("endloading");
        self.itemsAllRoomData = tempItems;
        self.roomIdOldData.options = tempRoomOldOptions;
        self.mapOldDataFunc();
        if (!self.roomIdOldData._id) self.gotoroomtypes();
      }
    },
    selectRoomTypeFunc() {
      const self = this;
      var temp = [];
      var tempName = [];
      self.itemsRoomName = [];
      var roomtypeSelect = self.itemsRoomType.find(
        p => p.value == self.addNewRoomData.roomtype
      );
      temp = roomtypeSelect?.room_name || [];
      // console.log(self.itemsAllRoomData, self.addNewRoomData.roomtype, self.addNewRoomData.name)
      roomtypeSelect?.room_name?.map(el => {
        var selecttyped = self.itemsAllRoomData.find(
          p => p.room_name_detail == el.room_name || p.room_name == el._id
        );
        if (selecttyped == undefined || self.addNewRoomData.name == el._id)
          tempName.push({
            _id: el?._id || "",
            name: el?.room_name || "",
            value: el?._id || ""
          });
      });

      self.itemsRoomName = tempName;
      if (self?.itemsRoomName?.length)
        self.addNewRoomData.name =
          typeof self?.roomIdOldData?.room_name === "object"
            ? self?.roomIdOldData?.room_name?.value || ""
            : self?.roomIdOldData?.room_name;
      if (self.addNewRoomData.roomtype == self?.roomIdOldData?.room_type) {
        self.addNewRoomData.name = self.selectoldroomid;
        var tempOptions = [];
        var defaultTempChoice = [];
        var tempOption = [];
        var tempChoice = [];
        if (self?.roomIdOldData?.options?.length) {
          self?.roomIdOldData?.options?.map(el => {
            defaultTempChoice = [];
            tempOption = [];
            tempChoice = [];
            if (el?.choices?.length) {
              el?.choices?.map(ele => {
                tempOption = roomtypeSelect?.options?.find(
                  p => p._id == el._id
                );
                tempChoice = tempOption?.choices?.find(
                  p => p._id == ele.choice
                );
                if (tempChoice?._id)
                  defaultTempChoice.push({
                    choice: tempChoice?.choice || "",
                    value: tempChoice?._id || "",
                    number: ele.number,
                    hover: false,
                    showwarn: false
                  });
              });
            } else {
              tempOption = roomtypeSelect?.options?.find(p => p._id == el._id);
            }
            tempOptions.push({
              _id: el?._id,
              option: el?.option,
              choices: tempOption?.choices,
              choosechoices: defaultTempChoice
            });
          });
        }
        self.addNewRoomData.options = tempOptions;
      }
    },
    mapOldDataFunc() {
      const self = this;
      self.addNewRoomData._id = self?.roomIdOldData?._id || "";
      self.addNewRoomData.roomtype =
        typeof self?.roomIdOldData?.room_type === "object"
          ? self?.roomIdOldData?.room_type?._id || ""
          : self?.roomIdOldData?.room_type;
      self.selectoldroomname =
        typeof self?.roomIdOldData?.room_name === "object"
          ? self?.roomIdOldData?.room_name?.value || ""
          : self?.roomIdOldData?.room_name;
      self.selectoldroomid =
        typeof self?.roomIdOldData?.room_name === "object"
          ? self?.roomIdOldData?.room_name?._id || ""
          : self?.roomIdOldData?.room_name;
      self.addNewRoomData.smokingrule = self?.roomIdOldData?.smoke || "";
      self.addNewRoomData.amount = self?.roomIdOldData?.room_number || "";
      self.addNewRoomData.normal = {
        name: "จำนวนผู้เข้าพักปกติ",
        value: "normal",
        amount: self?.roomIdOldData?.customer?.normal || 2,
        hover: false
      };
      self.addNewRoomData.max = {
        name: "จำนวนผู้เข้าพักปกติ",
        value: "normal",
        amount: self?.roomIdOldData?.customer?.max || 2,
        hover: false
      };
      self.addNewRoomData.roomcapacity =
        self.roomIdOldData?.room_size?.size || "";
      self.addNewRoomData.roomcapacityunit =
        self.roomIdOldData?.room_size?.measure || "";

      if (self?.addNewRoomData?.roomtype) {
        self.selectRoomTypeFunc();
      }
      self.checkcanupdate();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    increaseNumber(item) {
      return (item.amount = item.amount + 1);
    },
    decreaseNumber(item) {
      return (item.amount = item.amount - 1);
    },
    increaseNormalNumber() {
      if (this.addNewRoomData.normal.amount >= this.addNewRoomData.max.amount)
        this.addNewRoomData.max.amount = this.addNewRoomData.normal.amount + 1;
      return (this.addNewRoomData.normal.amount =
        this.addNewRoomData.normal.amount + 1);
    },
    decreaseNormalNumber() {
      return (this.addNewRoomData.normal.amount =
        this.addNewRoomData.normal.amount - 1);
    },
    increaseMaxNumber() {
      return (this.addNewRoomData.max.amount =
        this.addNewRoomData.max.amount + 1);
    },
    decreaseMaxNumber() {
      return (this.addNewRoomData.max.amount =
        this.addNewRoomData.max.amount - 1);
    },
    computedBedOfInroom() {
      var sum = 1;
      const self = this;
      sum = self.addNewRoomData.inroombeds.reduce((a, b) => {
        return a + b.amount;
      }, 0);
      return sum;
    },
    computedBedOfCommonSpace() {
      var sum = 1;
      const self = this;
      sum = self.addNewRoomData.commonspacebeds.reduce((a, b) => {
        return a + b.amount;
      }, 0);
      return sum;
    },
    initSmokingRules() {
      const self = this;
      const temp = [
        {
          _id: 0,
          name: self.$t("SignupStep2.smokinrule1"),
          value: "Smoking"
        },
        {
          _id: 1,
          name: self.$t("SignupStep2.smokinrule2"),
          value: "Non smoking"
        },
        {
          _id: 2,
          name: self.$t("SignupStep2.smokinrule3"),
          value:
            "I have  both smoking and non-smoking options for this room type"
        }
      ];
      self.itemsSmokingRules = temp;
    },
    async initRoomTypes() {
      const self = this;
      var temp = [];
      var item = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/room_type?lang=${localStorage.getItem("language") ||
              self.$store.state.language}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          item = res.data.result;
          item.map(el => {
            temp.push({
              ...el,
              name: el.value,
              value: el._id
            });
          });
          self.itemsRoomType = temp;
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message);
      } finally {
        EventBus.$emit("endloading");
        if (self?.addNewRoomData?.roomtype) {
          self.selectRoomTypeFunc();
        }
        EventBus.$emit("endloading");
      }
    },
    async initMeasures() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/setting/measure?lang=${localStorage.getItem("language") ||
              self.$store.state.language}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          res?.data?.result?.map(el => {
            temp.push({
              name: el.value,
              value: el._id
            });
          });
        }
        self.itemsRoomCapacityUnit = temp;
        self.addNewRoomData.roomcapacityunit = temp[0].name;
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message);
      }
    },
    changeBtnHover(index, i, value) {
      const self = this;
      if (value === "hover") {
        self.addNewRoomData.options[index].choosechoices[i].hoverdel = true;
      } else {
        self.addNewRoomData.options[index].choosechoices[i].hoverdel = false;
      }
    },
    increaseChoiceNumber(item) {
      this.forceRerender();
      return (item.number = item.number + 1);
    },
    decreaseChoiceNumber(item) {
      this.forceRerender();
      return (item.number = item.number - 1);
    },
    hoverRemoveChoice(item, action) {
      this.forceRerender();
      if (action === "hover") {
        return (item.hover = true);
      } else return (item.hover = false);
    },
    outRemoveChoice(item) {
      this.componentKey = 0;
      return (item.hover = false);
    },
    checkBedChoices(item, index, i) {
      const self = this;
      var temp = [];
      var data = [];
      var elementValue = document.getElementById(`newchoices_${i}`);

      temp = self.addNewRoomData.options[index].choosechoices || [];
      self?.addNewRoomData?.options[index]?.choices?.map((el, index) => {
        const finddata = temp?.find(p => p.value == el._id);
        if (!finddata || el._id == item.value) data.push(el);
      });

      if (!item.value) {
        item.showwarn = true;
        elementValue?.classList?.add("border-pink-focus");
      } else {
        item.showwarn = false;
        elementValue?.classList?.remove("border-pink-focus");
      }

      self.checkcanupdate();
      return data;
    },
    addNewChoice(choice, index) {
      const self = this;
      var temp = [];
      temp = self.addNewRoomData.options[index].choosechoices || [];
      // var indexOfData =
      //   self?.addNewRoomData?.options[index]?.choosechoices?.findIndex(
      //     (p) => p.value == choice
      //   ) !== undefined
      //     ? self?.addNewRoomData?.options[index]?.choosechoices?.findIndex(
      //         (p) => p.value == choice
      //       )
      //     : -1;
      // var tempChoice = self?.addNewRoomData?.options[index]?.choices?.find(
      //   (p) => p._id == choice
      // );

      // if (indexOfData > -1) {
      //   swal(
      //     self.$t("Alert.warn_title"),
      //     self.$t("Alert.alreadyhavedata"),
      //     self.$t("Alert.warn_label"),
      //     {
      //       button: false,
      //       timer: 3000,
      //     }
      //   );
      // } else {
      EventBus.$emit("loadingPage");
      temp.push({
        choice: "",
        value: "",
        number: 1,
        hover: false,
        showwarn: false
      });
      self.forceRerender();
      self.addNewRoomData.options[index].choosechoices = temp;
      //   setTimeout(() => {
      //     self.addNewRoomData.options[index].choice = "";
      //   }, 200);
      // }
    },
    removeNewChoice(item, optionIndex, i) {
      const self = this;
      self.forceRerender();
      if (
        optionIndex == 0 &&
        self?.addNewRoomData?.options[optionIndex]?.choosechoices?.length == 1
      ) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("SignupStep2.cantremoveoption"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      } else {
        self?.addNewRoomData?.options[optionIndex]?.choosechoices.splice(i, 1);
      }
      EventBus.$emit("loadingPage");
    },
    clearAddNewRoomDataFunc() {
      const self = this;
      const status = "init";
      self.addNewRoomData = {
        options: [],
        normal: {
          name: "จำนวนผู้เข้าพักปกติ",
          value: "normal",
          amount: 2,
          hover: false
        },
        max: {
          name: "จำนวนผู้เข้าพักสูงสุด",
          value: "maximum",
          amount: 2,
          hover: false
        },
        roomcapacity: "",
        roomcapacityunit: ""
      };
      EventBus.$emit("actionRoomType", status);
      EventBus.$emit("loadingPage");
    },
    addNewRoomDataFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var tempRoomOptions = [];
      var tempRoomChoices = [];
      self?.addNewRoomData?.options?.map(ele => {
        tempRoomChoices = [];
        ele?.choosechoices?.map(el => {
          if (ele?.choosechoices?.length && el.value) {
            tempRoomChoices.push({
              choice: el.value,
              number: el.number
            });
          }
        });
        tempRoomOptions.push({
          option: ele._id,
          choices: tempRoomChoices
        });
      });

      const obj = {
        _id: self?.addNewRoomData?._id || "",
        room_type: self?.addNewRoomData?.roomtype || "",
        room_name:
          typeof self?.addNewRoomData?.name === "object"
            ? self?.addNewRoomData?.name?.value || ""
            : self?.addNewRoomData?.name == self.selectoldroomname
            ? self.selectoldroomid
            : self?.addNewRoomData?.name,
        room_number: Number(self?.addNewRoomData?.amount) || 0,
        smoke: self?.addNewRoomData?.smokingrule || "",
        options: tempRoomOptions,
        customer: {
          normal: Number(self?.addNewRoomData?.normal?.amount) || 0,
          max: Number(self?.addNewRoomData?.max?.amount) || 0
        },
        room_size: {
          size: Number(self?.addNewRoomData?.roomcapacity) || 0,
          measure: self?.addNewRoomData?.roomcapacityunit || ""
        }
      };

      const elementRoomAmount = document.getElementById("room_number");
      const elementRoomSize = document.getElementById("room_size");

      if (
        !obj.room_type ||
        !obj.room_name ||
        !obj.smoke ||
        !obj.room_number ||
        obj.room_number === 0 ||
        !obj.customer.normal ||
        obj.customer.normal === 0 ||
        !obj.customer.max ||
        obj.customer.max === 0 ||
        !obj.room_size.size ||
        obj.room_size.size === 0 ||
        !obj.room_size.measure
      ) {
        EventBus.$emit("endloading");

        if (!obj.room_number || obj.room_number === 0) {
          self.showwarnamount = true;
          elementRoomAmount.classList.add("border-pink-focus");
        } else {
          self.showwarnamount = false;
          elementRoomAmount?.classList?.remove("border-pink-focus");
        }
        if (!obj.room_size.size || obj.room_size.size === 0) {
          self.showwarnunitnumber = true;
          elementRoomSize.classList.add("border-pink-focus");
        } else {
          self.showwarnunitnumber = false;
          elementRoomSize?.classList?.remove("border-pink-focus");
        }

        if (!obj.room_type) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t("SignupStep2.room_type")}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (!obj.room_name) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t(
              "SignupStep2.room_type_name"
            )}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (!obj.smoke) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t(
              "SignupStep2.smokingrule"
            )}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (!obj.room_number || obj.room_number === 0) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t(
              "SignupStep2.room_number"
            )}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (!obj.customer.normal || obj.customer.normal === 0) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t(
              "SignupStep2.normal_customer_number"
            )}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (!obj.customer.max || obj.customer.max === 0) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t(
              "SignupStep2.max_customer_number"
            )}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (!obj.room_size.size || obj.room_size.size === 0) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t("SignupStep2.room_size")}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else if (!obj.room_size.measure) {
          swal(
            self.$t("Alert.warn_title"),
            `${self.$t("Alert.pleasefill")}${self.$t("SignupStep2.room_unit")}`,
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.fillinfo"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } else {
        // var foundIndex = self.itemsAllRoomData.findIndex(x => x._id == self.roomID);
        // if (foundIndex > -1) self.itemsAllRoomData[foundIndex] = obj;
        self.canupdateroomtype = false;
        self.updateRoomTypes(obj);
      }
    },
    async updateRoomTypes(item) {
      const self = this;
      const obj = item;
      const linkItems = {
        link: `signup/room/${self.propertyID}`,
        name: "SignupRoomScreen",
        params: { propertyId: self.propertyID }
      };
      EventBus.$emit("loadingtillend");

      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API + `/property/room?room_id=${self.roomID}`,
          // `/property/room_typies/?field_id=${self.roomID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          self.canupdateroomtype = true;
          self.clearAddNewRoomDataFunc();
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          self.canupdateroomtype = true;
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        self.canupdateroomtype = true;
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
        console.log(error?.response?.data?.message);
      }
    },
    async changeStep(linkItems) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step2?property_id=${self.propertyID}&next=${0}`,
          null,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language
            }
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("changePathname", linkItems);
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            res?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000
          }
        );
      }
    },
    goback() {
      const self = this;
      var item = {
        link: `signup/room/${self.userToken}/${self.propertyID}`,
        name: "SignupRoomScreen",
        params: { userToken: self.userToken, propertyId: self.propertyID }
      };
      EventBus.$emit("changePathname", item);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";

.moving-left {
  /* height: 100px; */
  /* position: relative; */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0px);
  /* width: 100px; */
}

.moving-left:hover {
  transform: translateX(-10px);
}

.hover_numberbtn:hover {
  border: solid 1px transparent;
  color: #fff !important;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.v-input__slot {
  border: 1px solid var(--border-gray) !important;
}

.btn.btn-addtype:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.title-green {
  color: var(--green) !important;
}

.title-gray {
  color: var(--gray) !important;
}

.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  min-height: 42px !important;
  height: fit-content !important;
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
